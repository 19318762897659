import webApi from "./api"

export class SMSService {
  async sendSMS(data) {
		return webApi.post('sms/send-sms', data)
	}
}

const smsService = new SMSService()

export default smsService