<template>
  <div>
    <b-modal
      no-close-on-backdrop
      id="modal-guided-camera-sms"
      modal-class="modal-guided-camera-sms"
      ref="modal-guided-camera-sms"
      content-class="modal-guided-camera-sms"
      @show="openModal"
      @hidden="closeModal"
    >
      <template #modal-header>
        <p class="header mb-2">Get link by SMS</p>
        <p class="subheader mb-1">
          The Guided camera is available only on mobile devices. 
          Enter your mobile number below and we'll SMS you a link to access it!
        </p>
      </template>
      <label class="mb-2">Mobile number</label>
      <b-input
        v-model.trim="guidedCameraMobileNumber"
        ref="guidedCameraMobileNumber"
        placeholder="Enter your mobile number"
        autocomplete="off"
        required
        class="mobile-number-input"
        :class="[
          {
            error: $v.guidedCameraMobileNumber.$error,
          },
          'shadow-none',
        ]"
      />
      <p
        v-if="
          $v.guidedCameraMobileNumber.$dirty &&
          !$v.guidedCameraMobileNumber.required
        "
        class="msg-error mb-0"
      >
        <i>Please enter your mobile number</i>
      </p>
      <p
        v-else-if="
          $v.guidedCameraMobileNumber.$dirty &&
          (!$v.guidedCameraMobileNumber.valid || !$v.guidedCameraMobileNumber.maxLength)
        "
        class="msg-error mb-0"
      >
        <i>Please enter a valid mobile number starting with '04'</i>
      </p>
      <template #modal-footer>
        <button
          class="shadow-none send-sms-btn action-btn mb-2"
          :class="$v.guidedCameraMobileNumber.$error ? 'mt-5' : 'mt-4'"
          @click="sendSMS"
        >
          <b-spinner
            v-if="sendSMSIssue"
            label="Loading..."
            class="btn-loading"
          ></b-spinner>
          <template v-else> Send SMS </template>
        </button>
        <button @click="closeModal" class="shadow-none cancel-btn action-btn">
          Cancel
        </button>
      </template>
    </b-modal>
    <b-modal
      hide-footer
      hide-header
      id="modal-guided-camera-sms-success"
      modal-class="modal-guided-camera-sms-success"
      ref="modal-guided-camera-sms-success"
      content-class="modal-guided-camera-sms-success"
    >
      <img src="@/assets/images/checkmark.circle.png" class="img" />
      <p class="text-center">
        SMS sent!
      </p>
    </b-modal>
  </div>
</template>

<script>
import SMSService from "@/services/sms";
import { required, helpers, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { SET_GUIDED_CAMERA_MOBILE_NUMBER } from "@/store/authenticate-seller/actions"
import { logError } from "@/helper/utils";

const regexPhoneRule = helpers.regex(
  "number",
  /^04\d{8,}$/
);

export default {
  name: "GuidedCameraSMSModal",
  data() {
    return {
      sendSMSIssue: false,
    };
  },
  computed: {
    ...mapState({
      authenticateData: (state) => state.authenticateSeller,
      guidedCameraSMSUrl: (state) => state.authenticateSeller.guidedCameraSMSUrl,
    }),
    guidedCameraMobileNumber : {
      get() {
        return this.$store.state.authenticateSeller.guidedCameraMobileNumber;
      },
      set(value) {
        this.authenticateData.customerMobile = value;
        this.$store.dispatch(SET_GUIDED_CAMERA_MOBILE_NUMBER, value);
      }
    },
  },
  methods: {
    async sendSMS() {
      this.$v.$touch();
      if (!this.$v.guidedCameraMobileNumber.$invalid) {
        this.sendSMSIssue = true;
        try {
          const res = await SMSService.sendSMS({
            appraisalId: this.authenticateData.appraisalId,
            customerName: this.authenticateData.customerName,
            customerMobile: this.authenticateData.customerMobile,
            guidedCameraSMSUrl: this.guidedCameraSMSUrl,
          });
          this.sendSMSIssue = false;
          if (res.status === 201 && res.data) {
            this.$bvModal.hide("modal-guided-camera-sms");
            this.$bvModal.show("modal-guided-camera-sms-success");
          } else {
            this.$toast.clear();
            this.$toast.error("Failed to send SMS. Please try again later.");
          }
        } catch (err) {
          this.sendSMSIssue = false;
          if (err.response && err.response.status === 422) {
            const errors = err.response.data.errors;
            Object.keys(errors).forEach((field) => {
              this.$toast.clear();
              this.$toast.error(
                `${err.response.data.message}: ${errors[field][0]}`
              );
            });
          } else if (err.response) {
            this.$toast.clear();
            this.$toast.error(err.response.data.message);
          } else {
            logError(`Send guided camera SMS error, ${err}`);
          }
        }
      }
    },
    openModal() {
      this.$bvModal.show("modal-guided-camera-sms");
      if (this.authenticateData?.customerMobile && !this.guidedCameraMobileNumber) {
        this.$store.dispatch(SET_GUIDED_CAMERA_MOBILE_NUMBER, this.authenticateData.customerMobile);
      }
      this.$v.guidedCameraMobileNumber.$reset();
    },
    closeModal() {
      this.$bvModal.hide("modal-guided-camera-sms");
      this.$v.guidedCameraMobileNumber.$reset();
    },
  },
  validations: {
    guidedCameraMobileNumber: {
      required,
      valid: regexPhoneRule,
      maxLength: maxLength(10),
    },
  },
}
</script>

<style lang="scss">
#modal-guided-camera-sms___BV_modal_outer_{
  z-index: 1000002 !important;
  .modal-dialog {
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    width: 350px !important;
    font-style: normal;
  }
  .modal-header {
    border: 0 !important;
    padding: 1rem 1rem 0rem !important;
    display: block !important;
    .header {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #191D23;
      margin-bottom: 0;
    }
    .subheader {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  .modal-body {
    padding-bottom: 0;
    label {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
    .form-control {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      font-family: Avenir Next LT W05 Regular;
      line-height: 19px;
    }
    select.form-control {
      padding: 10px 8px;
      font-size: 14px;
      font-weight: 400 !important;
      line-height: 19px;
      height: 40px !important;
      &.gray {
        color: #64748B !important;
      }
    }
    select.form-control.select-custom {
      border: 1px solid #D0D5DD;
      border-radius: 4px;
    }
    .mobile-number-input {
      font-size: 16px;
      height: 50px;
      color: #000000;
      border-color: #ced4da;
      &:focus {
        box-shadow: none;
        border-color: var(--primary-color);
        outline: 0;
      }
      &:disabled {
        border-color: transparent;
      }
    }
    .error,
    .mobile-number-input.form-control.invalid {
      border-color: red !important;
    }
    .msg-error {
      position: absolute;
      font-size: 12px;
      color: red;
      text-align: left;
      float: right;
      margin-top: 2px;
    }
  }
  .modal-footer {
    flex-direction: column !important;
    border: 0 !important;
    padding-top: 0;
    justify-content: space-between !important;
    padding: 0rem 1rem 1.5rem;
    button {
      font-family: Avenir Next LT W05 Regular !important;
    }
    .action-btn {
      width: 100%;
      border-radius: 4px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 6px 12px !important;
      text-transform: none !important;
      font-size: 16px;
      height: 44px;
      outline: none !important;
      border-radius: 8px !important;
    }
    .send-sms-btn {
      background: var(--primary-color);
      color: #ffffff;
      border: 1px solid var(--primary-color);
    }
    .cancel-btn {
      background: #ffffff;
      color: #344054;
      border: 1px solid #E7EAEE;
    }
  }
}
#modal-guided-camera-sms-success___BV_modal_outer_{
  z-index: 1000002 !important;
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    background: var(--primary-color);
    border-radius: 8px;
    width: 156px;
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 10px;

      img {
        padding: 20px 40px;
      }

      p {
        text-align: center;
        color: #FFFFFF;
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}

@media screen and(min-width: 900px) {
  #modal-guided-camera-sms-success___BV_modal_outer_{
    .modal-content {
      width: 295px;

      .modal-body {
        flex-direction: row;
        padding: 10px 33px 10px 15px;

        img {
          padding: 20px 20px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>