<template>
  <div class="overflow-hidden">
    <header-view />
    <b-container>
      <router-view />
        <ReportIssueModal />
        <GuidedCameraSMSModal />
    </b-container>
    <footer-view/>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/Header";
import FooterView from "@/components/layout/Footer";
import ReportIssueModal from "@/components/common/ReportIssueModal";
import GuidedCameraSMSModal from "@/components/common/GuidedCameraSMSModal";
import { mapState } from "vuex";

export default {
  name: "Index",
  components: {
    HeaderView,
    FooterView,
    ReportIssueModal,
    GuidedCameraSMSModal,
  },
  computed: {
    ...mapState({
      steps: (state) => state.common.steps,
      step: (state) => state.common.step,
      authenticateData: (state) => state.authenticateSeller,
      brand: (state) => state.commonVariables.brandName,
      uxeId: (state) => state.commonVariables.uxeId,
    }),
  },
};
</script>

<style lang="scss">
.container {
  padding: 0;
  // 100vh - header - footer - stepper margin top
  min-height: calc(100vh - 75px - 120px - 30px);
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0 15px;
  }
}
</style>
