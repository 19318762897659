var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-guided-camera-sms",attrs:{"no-close-on-backdrop":"","id":"modal-guided-camera-sms","modal-class":"modal-guided-camera-sms","content-class":"modal-guided-camera-sms"},on:{"show":_vm.openModal,"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('p',{staticClass:"header mb-2"},[_vm._v("Get link by SMS")]),_c('p',{staticClass:"subheader mb-1"},[_vm._v(" The Guided camera is available only on mobile devices. Enter your mobile number below and we'll SMS you a link to access it! ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"shadow-none send-sms-btn action-btn mb-2",class:_vm.$v.guidedCameraMobileNumber.$error ? 'mt-5' : 'mt-4',on:{"click":_vm.sendSMS}},[(_vm.sendSMSIssue)?_c('b-spinner',{staticClass:"btn-loading",attrs:{"label":"Loading..."}}):[_vm._v(" Send SMS ")]],2),_c('button',{staticClass:"shadow-none cancel-btn action-btn",on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")])]},proxy:true}])},[_c('label',{staticClass:"mb-2"},[_vm._v("Mobile number")]),_c('b-input',{ref:"guidedCameraMobileNumber",staticClass:"mobile-number-input",class:[
        {
          error: _vm.$v.guidedCameraMobileNumber.$error,
        },
        'shadow-none' ],attrs:{"placeholder":"Enter your mobile number","autocomplete":"off","required":""},model:{value:(_vm.guidedCameraMobileNumber),callback:function ($$v) {_vm.guidedCameraMobileNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"guidedCameraMobileNumber"}}),(
        _vm.$v.guidedCameraMobileNumber.$dirty &&
        !_vm.$v.guidedCameraMobileNumber.required
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter your mobile number")])]):(
        _vm.$v.guidedCameraMobileNumber.$dirty &&
        (!_vm.$v.guidedCameraMobileNumber.valid || !_vm.$v.guidedCameraMobileNumber.maxLength)
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter a valid mobile number starting with '04'")])]):_vm._e()],1),_c('b-modal',{ref:"modal-guided-camera-sms-success",attrs:{"hide-footer":"","hide-header":"","id":"modal-guided-camera-sms-success","modal-class":"modal-guided-camera-sms-success","content-class":"modal-guided-camera-sms-success"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/checkmark.circle.png")}}),_c('p',{staticClass:"text-center"},[_vm._v(" SMS sent! ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }