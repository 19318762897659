<template>
  <div>
    <b-modal
      no-close-on-backdrop
      id="modal-issue"
      modal-class="modal-issue"
      ref="modal-issue"
      content-class="modal-issue"
      @show="resetModal"
      @hide="resetModal"
    >
      <template #modal-header>
        <p>Report issue</p>
      </template>
      <label>Topic</label>
      <custom-select
        v-model="topic"
        :options="topics"
        :error="$v.topic.$error"
        helperText="Please select topic"
        name="topic"
      />
      <label>Description</label>
      <custom-text-area
        v-model="descriptionIssue"
        :error="$v.descriptionIssue.$error"
        :placeholder="descriptionIssuePlaceholder"
        name="descriptionIssue"
        helperText="Please enter description"
        @keyup="descriptionIssueValidate()"
      />
      <template #modal-footer>
        <button @click="resetModal" class="shadow-none cancel-btn action-btn">
          Cancel
        </button>
        <button
          class="shadow-none submit-btn action-btn"
          @click="submitIssue"
        >
          <b-spinner
            v-if="submittingIssue"
            label="Loading..."
            class="btn-loading"
          ></b-spinner>
          <template v-else> Submit </template>
        </button>
      </template>
    </b-modal>
    <b-modal
      hide-footer
      hide-header
      id="modal-report-issue-success"
      modal-class="modal-report-issue-success"
      ref="modal-report-issue-success"
      content-class="modal-report-issue-success"
    >
      <img src="@/assets/images/checkmark.circle.png" class="img" />
      <p>
        Your issue has been successfully reported. A {{ brandName }} team member
        will be in touch with you shortly.
      </p>
    </b-modal>
  </div>
</template>

<script>
import LeadUpdateService from "@/services/lead-update";
import { required } from "vuelidate/lib/validators";
import { getParamFromURL, logError } from "@/helper/utils";
import { mapState } from "vuex";
import CustomSelect from "@/components/common/CustomSelect";
import CustomTextArea from "@/components/common/CustomTextArea"

export default {
  name: "ReportIssueModal",
  components: {
    CustomSelect,
    CustomTextArea,
  },
  data() {
    return {
      topic: "",
      descriptionIssue: "",
      descriptionIssuePlaceholder: "Start typing here..",
      submittingIssue: false,
    };
  },
  computed: {
    ...mapState({
      authenticateData: (state) => state.authenticateSeller,
      brand: (state) => state.commonVariables.brandName,
      uxeId: (state) => state.commonVariables.uxeId,
    }),
    topics() {
      return [
        "I want to cancel my listing",
        "I want to add more photos",
        "Photos are not uploading",
        "I can't delete my photos",
        "Car rego is incorrectly hidden",
        "Other",
      ];
    },
    brandName() {
      return this.brand || "Autoflip";
    },
  },
  methods: {
    async submitIssue() {
      this.$v.$touch();
      this.submittingIssue = true;
      if (!this.$v.topic.$invalid && !this.$v.descriptionIssue.$invalid) {
        const topic = this.topic;
        const description = this.descriptionIssue;
        try {
          const res = await LeadUpdateService.reportIssues({
            appraisalId: this.authenticateData.appraisalId,
            customerName: this.authenticateData.customerName,
            customerEmail: this.authenticateData.email,
            customerMobile: this.authenticateData.customerMobile,
            topic,
            description,
            additionalDetails: {
              source: getParamFromURL(window.location.href, "utm_source") || '',
              userAgentString: navigator.userAgent,
              url: window.location.href,
            },
            locid: parseInt(this.authenticateData.locid),
            leadId: this.authenticateData.leadId,
            uxeId: this.uxeId,
          });
          this.submittingIssue = false;
          if (res.status === 201 && res.data) {
            this.$bvModal.hide("modal-issue");
            this.$bvModal.show("modal-report-issue-success");
          }
        } catch (err) {
          this.submittingIssue = false;
          if (err.response && err.response.status === 422) {
            const errors = err.response.data.errors;
            Object.keys(errors).forEach((field) => {
              this.$toast.clear();
              this.$toast.error(
                `${err.response.data.message}: ${errors[field][0]}`
              );
            });
          } else if (err.response) {
            this.$toast.clear();
            this.$toast.error(err.response.data.message);
          } else {
            logError(`Submit issue error: ${err}`);
          }
        }
      }
    },
    resetModal() {
      this.$bvModal.hide("modal-issue");
      this.topic = "";
      this.descriptionIssue = "";
      this.$v.topic.$reset();
      this.$v.descriptionIssue.$reset();
    },
    descriptionIssueValidate: function () {
      this.$v.descriptionIssue.$reset();
    },
  },
  validations: {
    topic: {
      required,
    },
    descriptionIssue: {
      required,
    },
  },
}
</script>

<style lang="scss">
#modal-issue___BV_modal_outer_{
  z-index: 1000002 !important;
  .modal-dialog {
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    width: 350px !important;
    font-style: normal;
  }
  .modal-header {
    border: 0 !important;
    padding: 1rem 1rem 0rem !important;
    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #191D23;
      margin-bottom: 0;
    }
  }
  .modal-body {
    padding-bottom: 0;
    label {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
    .form-control {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      font-family: Avenir Next LT W05 Regular;
      line-height: 19px;
    }
    select.form-control {
      padding: 10px 8px;
      font-size: 14px;
      font-weight: 400 !important;
      line-height: 19px;
      height: 40px !important;
      &.gray {
        color: #64748B !important;
      }
    }
    select.form-control.select-custom {
      border: 1px solid #D0D5DD;
      border-radius: 4px;
    }
  }
  .modal-footer {
    border: 0 !important;
    padding-top: 0;
    justify-content: space-between !important;
    padding: 0 1rem 1.5rem;
    button {
      font-family: Avenir Next LT W05 Regular !important;
    }
    .action-btn {
      border-radius: 4px !important;
      margin: 0 !important;
      padding: 6px 12px !important;
      text-transform: none !important;
      font-size: 16px;
      height: 44px;
      outline: none !important;
    }
    .cancel-btn {
      width: 100px;
      background: #E7EAEE;
      color: #64748B;
      border: 1px solid #E7EAEE;
    }
    .submit-btn {
      width: 200px;
      background: var(--primary-color);
      color: #ffffff;
      border: 1px solid var(--primary-color);
    }
  }
}
#modal-report-issue-success___BV_modal_outer_{
  z-index: 1000002 !important;
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    background: var(--primary-color);
    border-radius: 8px;
    width: 156px;
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 10px;

      img {
        padding: 20px 40px;
      }

      p {
        text-align: center;
        color: #FFFFFF;
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}

@media screen and(min-width: 900px) {
  #modal-report-issue-success___BV_modal_outer_{
    .modal-content {
      width: 295px;

      .modal-body {
        flex-direction: row;
        padding: 10px 33px 10px 15px;

        img {
          padding: 20px 20px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>